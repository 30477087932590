<template>
	<div>
		<ByCancel @close="close"></ByCancel>
		<h3 class="title">隱私權政策</h3>
		<div class="content">
			<div class="part">
				<p>
					點溡科技尊重並保護您的隱私權，並遵守《個人資料保護法》及其他相關法規。您在使用本公司提供的手機點餐服務時，我們將依本政策收集、使用並保護您的個人資料。
				</p>
			</div>

			<div class="part">
				<p>一、資料收集目的</p>
				<p>
					訂單處理：為便於您快速、準確完成訂單，我們將收集您在訂購過程中提供的資料。
				</p>
				<p>
					行銷活動：基於提供更佳服務之目的，我們可能使用您的聯絡方式向您推廣促銷活動、優惠資訊等。
				</p>
				<p>顧客服務：為提供優質客服支援，回應您對訂單、產品、或服務的詢問。</p>
				<p>
					網站優化：為提升使用者體驗，我們可能基於匿名性進行數據分析，改善點餐平台的操作便利性。
				</p>
			</div>

			<div class="part">
				<p>二、收集之個人資料範圍</p>
				<p>
					基本聯絡資訊：包括您的姓名、電話號碼、地址等，僅用於聯絡和訂單確認。
				</p>
				<p>
					定位資訊：於您授權下，我們可能會使用您所在的地理位置信息，以提供最佳配送或就近取餐建議。
				</p>
				<p>
					交易資料：包括您訂購的產品、付款方式等，以便進行交易記錄、售後服務。
				</p>
			</div>

			<div class="part">
				<p>三、個人資料的使用</p>
				<p>
					內部用途：我們會將資料用於訂單處理、行銷、活動通知等，僅限於內部人員使用。
				</p>
				<p>
					委外處理：如需委託第三方支付、物流公司等進行交易處理，我們將要求受託單位遵守個資法及保密協議。
				</p>
				<p>
					統計分析：所有資料將以去識別化方式進行使用，用於分析網站流量、點餐習慣，以改進服務。
				</p>
			</div>

			<div class="part">
				<p>四、個人資料的分享</p>
				<p>
					我們承諾不會將您的個人資料出售或出借予第三方，但在以下情況下可能提供您的資料：
				</p>
				<p>法律要求：如遇司法機關或其他主管機關依法要求提供資料時。</p>
				<p>權益保護：為保護本公司、用戶或第三方之合法權益。</p>
			</div>

			<div class="part">
				<p>五、資料保存與保護</p>
				<p>
					保存期間：我們將於業務處理期間內保留您的個人資料，除非法律另有規定。
				</p>
				<p>
					資料安全：採用嚴格的資訊保護措施，包括加密技術和防火牆等，防止資料被竊或洩漏。
				</p>
			</div>

			<div class="part">
				<p>六、您的權利</p>
				<p>
					依《個人資料保護法》，您有權隨時查詢、更正、刪除或限制使用您的個人資料。如需行使權利，請聯絡我們的客服部門。
				</p>
			</div>

			<div class="part">
				<p>七、政策更新</p>
				<p>
					本隱私權政策將因應法律、技術更新等因素適時進行調整，我們會將修訂後的內容發布於網站，建議您定期查看以了解最新的隱私條款。
				</p>
			</div>

			<div class="part">
				<p>八、聯絡方式</p>
				<p>
					如您對本政策有任何疑問，或欲行使您的權利，請聯絡我們的客服支援團隊。信箱：ds.services.work@gmail.com。
				</p>
			</div>
		</div>
		<ByFooter />
	</div>
</template>

<script>
export default {
	methods: {
		close() {
			this.$router.go(-1)
		},
	},
}
</script>

<style lang="sass" scoped>
.title
	position: absolute
	top: -14px
	left: 50%
	transform: translateX(-50%)

.content
	+fc
	flex-direction: column
	padding: 0 17px 30px
	margin-top: 15px
	width: 100%
	font-size: 15px
	line-height: 1.5
	text-align: justify

	> p
		width: 100%

.part
	width: 100%
	margin: 25px 0 0
</style>
